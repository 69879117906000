<template>
  <div>
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :default-active="activeMenu"
      :unique-opened="true"
      :collapse-transition="false"
      router
    >
      <!-- <menu-item :tree="scopeRoutes"></menu-item> -->
      <menu-item
        v-for="(route, index) in scopeRoutes"
        :key="route.path + index"
        :item="route"
        :base-path="route.path"
      />
    </el-menu>
  </div>
</template>

<script>
import { baseRoutes } from "@/router/route.js";
import MenuItem from "./MenuItem";

export default {
  name: "NavMenu",
  components: {
    MenuItem,
  },
  props: ["isCollapse"],
  data() {
    return {
      // activeMenu: "",
      scopeRoutes: [],
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;

      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    // showLogo() {
    //     return this.$store.state.settings.sidebarLogo;
    // },
    // variables() {
    //     return variables;
    // },
    // isCollapse() {
    //     return !this.sidebar.opened;
    // }
  },
  created() {
    this.scopeRoutes = baseRoutes.concat(this.$store.state.permission.funcList);
    console.log("ScopeRoutes:", this.scopeRoutes);
  },
  watch: {
    $route: {
      handler() {
        const code = this.$route.meta.code.substring(7);
        code !== "RB"
          ? (this.activeMenu = this.$route.path)
          : (this.activeMenu = "/open/issue");
      },
      immediate: true,
    },
  },
};
</script>

<!-- <style lang="scss" scoped>
.active_menu_item {
  background-color: #1890ff;
  opacity: 1;
}
</style> -->
