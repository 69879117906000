<template>
  <div class="explain-wrap">
    <el-tabs v-model="activeTab">
      <el-tab-pane label="销售协同" name="tab_01">
        <el-image :src="staticFile.systemExplainXsxt.url" :alt="staticFile.systemExplainXsxt.name" />
      </el-tab-pane>
      <el-tab-pane label="采购协同" name="tab_02">
        <el-image :src="staticFile.systemExplainCgxt.url" :alt="staticFile.systemExplainCgxt.name" />
      </el-tab-pane>
      <el-tab-pane label="开票管理" name="tab_03">
        <el-image :src="staticFile.systemExplainKpgl.url" :alt="staticFile.systemExplainKpgl.name" />
      </el-tab-pane>
      <el-tab-pane label="收票中心" name="tab_04">
        <el-image :src="staticFile.systemExplainSpzx.url" :alt="staticFile.systemExplainSpzx.name" />
      </el-tab-pane>
      <el-tab-pane label="发 票 池" name="tab_05">
        <el-image :src="staticFile.systemExplainFpzx.url" :alt="staticFile.systemExplainFpzx.name" />
      </el-tab-pane>
      <el-tab-pane label="抵扣认证" name="tab_06">
        <el-image :src="staticFile.systemExplainDkrz.url" :alt="staticFile.systemExplainDkrz.name" />
      </el-tab-pane>
      <el-tab-pane label="报销管理" name="tab_07">
        <el-image :src="staticFile.systemExplainBxgl.url" :alt="staticFile.systemExplainBxgl.name" />
      </el-tab-pane>
    </el-tabs>
    <div class="description">{{content}}</div>
  </div>
</template>

<script>
import StaticFile from '@/assets/js/static-file'

export default {
  name: 'MenuExplain',
  props: ['content'],
  data () {
    return {
      activeTab: 'tab_01',
      staticFile: StaticFile,
    }
  }
}
</script>

<style lang="scss" scoped>
  .explain-wrap {
    .el-image {
      margin: 20px 0px;
    }
    .description {
      font-size: 16px;
      color: #666666;
    }
  }
</style>
