//websocket事件常量
export const constantsWs = {
  //待办
  TODO: 'TODO',
  //pc端
  TERMINAL: 'pc',
  // 申报
  DECLARE: 'declare',
  //系统级别通知
  SYSTEM_NOTICE: 'SYSTEM_NOTICE',
}