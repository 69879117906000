<template>
  <div v-if="!item.hidden">
    <!-- <div class="pdt-menu" v-for="child in tree" :key="child.index">
      <template v-if="child.meta && child.meta.menu">
        <el-submenu v-if="hasMenus(child)" :index="child.path">
          <template slot="title">
            <img v-if="child.meta && child.meta.icon" :src="child.meta.icon" />
            <span>{{ child.meta.title }}</span>
          </template>
          <menu-item
            :tree="child.children"
            :base-path="resolvePath(child.path)"
          ></menu-item>
        </el-submenu>
        <el-menu-item v-else :index="resolvePath(child.path)">
          <img v-if="child.meta && child.meta.icon" :src="child.meta.icon" />
          <span>{{ child.meta.title }}</span>
        </el-menu-item>
      </template>
    </div> -->

    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
        (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
        !item.alwaysShow
      "
    >
      <app-link
        v-if="onlyOneChild.meta"
        :to="resolvePath(onlyOneChild.path, onlyOneChild.query)"
      >
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{ 'submenu-title-noDropdown': !isNest }"
        >
          <item
            :icon="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            :title="onlyOneChild.meta.title"
          />
        </el-menu-item>
      </app-link>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template slot="title">
        <item
          v-if="item.meta"
          :icon="item.meta && item.meta.icon"
          :title="item.meta.title"
        />
      </template>
      <menu-item
        v-for="(child, index) in item.children"
        :key="child.path + index"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import path from "path";
import { isExternal } from "@/utils/validate";
import Item from "@/layout/components/Sidebar/Item";
import AppLink from "@/layout/components/Sidebar/Link";
import FixiOSBug from "@/layout/components/Sidebar/FixiOSBug";

export default {
  name: "menuItem",
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: "",
    },
  },
  data() {
    this.onlyOneChild = null;
    return {};
  },
  methods: {
    // hasMenus: (menu) => {
    //   return (
    //     menu.meta &&
    //     menu.meta.menu &&
    //     menu.children &&
    //     menu.children.some((i) => i.meta && i.meta.menu)
    //   );
    // },
    // resolvePath(routePath) {
    //   return path.resolve(this.basePath, routePath);
    // },
    hasOneShowingChild(children = [], parent) {
      if (!children) {
        children = [];
      }
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item;
          return true;
        }
      });

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true;
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: "", noShowingChildren: true };
        return true;
      }

      return false;
    },
    resolvePath(routePath, routeQuery) {
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(this.basePath)) {
        return this.basePath;
      }
      if (routeQuery) {
        let query = JSON.parse(routeQuery);
        return { path: path.resolve(this.basePath, routePath), query: query };
      }
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>

<style scoped lang="scss">
.pdt-menu {
  img {
    margin-right: 14px;
    height: 14px;
    width: 14px;
  }
}

.el-menu--collapse .pdt-menu {
  .el-menu-item {
    text-align: center;
  }

  .el-submenu {
    text-align: center;
    ::v-deep .el-submenu__icon-arrow {
      display: none;
    }
  }

  span {
    display: none;
  }

  img {
    margin-right: 0;
    vertical-align: middle;
  }
}
</style>