import { post, get } from './index.js';

/**
 * 登录
 * @param {*} data
 */
export const ReqSignIn = (data) => {
  return post('/login', data).then((res) => res);
};

/**
 * 登录
 * @param {*} data
 */
export const getLogin = (data) => {
  return post('/login/getLogin', data).then((res) => res);
};

/**
 * 微信登录
 * @param {*} data
 */
export const weiXinLogin = () => {
  return get('/login/wechat/get-login-url', {}).then((res) => res);
};

/**
 * 登出
 * @returns {Promise<any>}
 * @constructor
 */
export const ReqSignOff = () => {
  return post('/logout', {}).then((res) => res);
};

/**
 * 校验账户名
 * @param data
 * @returns {Promise<unknown>}
 */
export const checkUserNameHaven = (data) => {
  return post('/login/checkUsername', data).then((res) => res);
};

/**
 * 校验手机号是否存在
 * @param data
 * @returns {Promise<unknown>}
 */
export const checkPhoneHaven = (data) => {
  return post('/login/checkPhone', data).then((res) => res);
};

/**
 * 发送短信验证码
 * @param data
 * @returns {Promise<unknown>}
 */
export const sendSms = (data) => {
  return post('/login/sendSms', data).then((res) => res);
};

/**
 * 注册新用户
 * @param data
 * @returns {Promise<unknown>}
 */
export const register = (data) => {
  return post('/register', data).then((res) => res);
};

/**
 * 修改密码（忘记密码找回）
 * @param data
 * @returns {Promise<unknown>}
 */
export const restPassword = (data) => {
  return post('/login/restPassword', data).then((res) => res);
};

/**
 * 校验组织名称是否重复
 * @param data
 * @returns {Promise<unknown>}
 */
export const checkOrganization = (data) => {
  return post('/login/checkOrganization', data).then((res) => res);
};

// 修改密码
export const editPassword = (data) => {
  return post('/login/editPassword', data).then((res) => res);
};
