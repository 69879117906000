<template>
    <el-dialog append-to-body
            :title="title"
            :show="show"
            :visible.sync="visible"
            @close="$emit('update:show', false)"
            width="480px"
    >
        <div class="dialog-body">
            <div class="body-top">
                <label-span label="姓名：">
                    <span style="font-weight: 500;">{{joinApplyData.applyName}}</span>
                </label-span>
                <label-span label="组织名称：">
                    <span style="font-weight: 500;">{{joinApplyData.orgName}}</span>
                </label-span>
                <label-span label="组织税号：">
                    <span style="font-weight: 500;">{{joinApplyData.orgTaxNo}}</span>
                </label-span>
            </div>
            <div class="body-line"></div>
            <div class="body-bottom">
                <div class="title">申请理由</div>
                <div class="reason">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" disabled v-model="joinApplyData.reason"/>
                </div>
            </div>
        </div>
        <div :class="processed == false?'dialog-footer-left':'dialog-footer-right'" slot="footer">
            <div v-if="processed" :class="'info-operate-'+ data.resultType" class="operate-button">
                <span>已被</span>
                <span style="margin: 0 4px">{{data.handler}}</span>
                <span>{{data.resultType == 'success' ? '同意':'拒绝'}}</span>
            </div>
            <div>
                <div v-if="processed">
                    <el-button type="primary" @click="visible = false">我知道了</el-button>
                </div>
                <div v-else>
                    <el-button @click="handleProcessedToDoList('refuse')">拒绝</el-button>
                    <el-button type="primary" @click="handleProcessedToDoList('agree')">同意</el-button>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {updateToDoListProcessed} from "@/service/to-do-list";

    export default {
        name: "OrganizationJoinApply",
        props: {
            show: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: () => {
                }
            }
        },
        data() {
            return {
                title: '申请加入组织',
                visible: false,
                failVisible: false,
                failContent: '',
            }
        },
        watch: {
            show() {
                this.visible = this.show;
            }
        },
        computed: {
            joinApplyData() {
                if (typeof (this.data.data) == "undefined") {
                    return {
                        applyName: "",
                        orgName: "",
                        orgTaxNo: "",
                        reason: ""
                    };
                } else {
                    return JSON.parse(this.data.data);
                }
            },
            processed() {
                return this.data.processed;
            }
        },
        methods: {
            /* 待办事项处理 */
            handleProcessedToDoList(type) {
                this.visible = false;
                let processedResult = type == 'agree' ? '已同意' : "已拒绝";
                let resultType = type == 'agree' ? 'success' : "failure";
                updateToDoListProcessed({
                    id: this.data.id,
                    type: this.data.type,
                    processedResult: processedResult,
                    resultType: resultType
                }).then(res => {
                    if (res.success) {
                        if (res.data.result) {
                            this.toast("处理成功！", 'success');
                        } else {
                            this.toast("处理失败！", 'error');
                        }
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dialog-body {
        text-align: left;

        .body-top {
            .label-span {
                margin-bottom: 14px;
            }

            & {
                margin-bottom: 24px;
            }
        }

        .body-line {
            margin: 0 -24px;
            border: 1px solid #EEEEEE;
            background-color: #EEEEEE;
        }

        .body-bottom {
            margin-top: 24px;

            .title {
                width: 56px;
                height: 20px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                margin-bottom: 8px;
            }
        }
    }

    .dialog-footer-right {
        display: flex;
        justify-content: space-between;
    }

    .dialog-footer-left {
        display: flex;
        justify-content: flex-end;
    }

    ::v-deep .el-dialog__header {
        padding: 24px 24px 0 24px;
    }

    ::v-deep .el-dialog__body {
        padding: 24px;
    }

    ::v-deep .el-dialog__footer {
        padding: 0px 24px 24px 24px;
    }
</style>
