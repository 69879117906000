<template>
  <div class="menu-header">
    <!--折叠-->
    <img v-show="isCollapse" :src="imgValue" alt="logo" />
    <!--展开-->
    <img v-show="!isCollapse" :src="imgValue" alt="logo" />
  </div>
</template>

<script>
import { getUserLogo } from '@/service/user';

export default {
  name: 'menuHeader',
  props: ['isCollapse'],
  data() {
    return {
      setImage: require('@/assets/logo/pdt.png'),
      titleName: '票单通',
      setImageDB: ''
    };
  },
  computed: {
    imgValue: function () {
      return this.setImageDB == null || this.setImageDB == '' ? this.setImage : this.setImageDB;
    }
  },
  created() {
    this.getLogoData();
  },
  methods: {
    //获取登录用户的logo信息
    async getLogoData() {
      getUserLogo().then((res) => {
        this.setImageDB = res.data.navigationCollapseImg;
        let titleName = res.data.titleName;
        if (titleName == null || titleName == '') {
          titleName = this.titleName;
        }
        let titleImg = res.data.titleImg;
        if (titleImg == null || titleImg == '') {
          titleImg = 'favicon.ico';
        }
        this.handleTitle(titleName, titleImg);
        this.handleTitleStorage(titleName, titleImg);
      });
    },
    handleTitleStorage(titleName, titleImg) {
      localStorage.setItem('titleKey', titleName);
      localStorage.setItem('titleImg', titleImg);
      localStorage.setItem('setImageDB', this.setImageDB);
    },
    handleTitle(titleName, titleImg) {
      document.title = titleName;
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = titleImg;
      document.getElementsByTagName('head')[0].appendChild(link);
    }
  }
};
</script>

<style scoped lang="scss">
.menu-header {
  display: flex;
  height: 56px;
  width: 100%;
  margin-top: 4px;
  justify-content: center;
  align-items: center;
  color: #fff;

  img {
    height: 44px;
  }

  span {
    margin-left: 8px;
  }
}
</style>
